import API from '@/service/admin-service'
let getToken = ()=>{ return localStorage.getItem('userAccessToken') }
export default {
    async getAllComponent(page, limit, filters){
        try {
            let params = {
                limit: limit,
                page: page,
                name: filters.name,
            }
            var res = await API.getAdminApi('api/admin/component/getall', { userid : getToken()},params);
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)    
        }
    },
    async deleteComponent(componentid){
        try {
            var res = await API.postAdminApi('api/admin/component/delete', { userid : getToken()},{componentid : componentid});
            return Promise.resolve(res)
        } catch (err) {
            return Promise.reject(err)    
        }
    }
   
}