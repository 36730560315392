<template>
        <Accordion class="w-100">
            <AccordionTab>
                <template #header>
                    <span><i class="fas fa-filter  p-text-info change" ></i>Filter</span>
                </template>
                <div class="row">
                    <div class="col-md-6">
                            <InputText type="text" v-model="filter.name" placeholder="component name "/>                               
                    </div>
                    <div class="col-md-3">
                        <Button class="p-button-info" icon="fas fa-filter" label="Filter"  @click="getAllComponent()" iconPos="left"  />
                        <Button class="p-button-info p-button-text" label="Clear" icon="pi pi-times"  @click="filter = {}" iconPos="left"  />                              
                    </div>
                </div>
            </AccordionTab>
        </Accordion>
        <div class="tables-container">
            <div class="table-design">
                 <!-- component Show -->
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Components</th>
                            <th scope="col">Design</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="c in components" :key ='c'>
                            <td scope="row" data-label="Components">
                                <div class="row mx-0">
                                    <div class="col-xl-6 col-12">
                                        <div class="detail-box">
                                            <span style="width: unset;"> {{c.name}} </span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td scope="row" data-label="Design">
                                <div class="col-xl-6 col-12">
                                    <div class="detail-box">
                                        <span><i class="fas fa-palette mr-1" :style="{color : c.color}"></i></span>
                                        <span>{{c.color}}</span>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-12">
                                    <div class="detail-box">
                                        <span><i style="font-family: 'FontAwesome'" :style="{color : c.color}">{{String.fromCharCode('0x'+c.icon)}}</i></span>
                                        <span>{{c.icon}}</span>
                                    </div>
                                </div>                                
                            </td>
                            <td scope="row" data-label="Created">
                                <div class="detail-box">
                                    <span><i class="fas fa-calendar-minus"></i></span>
                                    <span>{{c.date}}</span>
                                </div>
                                <div class="detail-box">
                                    <span><i class="fas fa-clock"></i></span>
                                    <span>{{new Date(c.createdon).toLocaleTimeString()}}</span>
                                </div>
                            </td>
                            <td scope="row" data-label="Action" class="d-flex align-items-center">
                                <div class="action-btn">
                                    <Button icon="fas fa-edit" class="p-button-outlined p-button-info" @click="editComponent(c._id)" />
                                    <Button icon="fas fa-trash-alt" class="p-button-outlined p-button-danger" @click =' deleteComponetModal = true; componentid = c._id'/>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                 <Paginator 
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    v-model:first= "page" v-model:rows= "limit" :totalRecords="totalRecords" :rowsPerPageOptions="[5, 10,20,30]" @page="onPage($event)">
                        <template #left="slotProps">
                            Showing {{ ((slotProps.state.first+1) > (totalRecords)) ? totalRecords : slotProps.state.first+1}} to {{((slotProps.state.page+1)*limit > totalRecords) ? totalRecords : (slotProps.state.page+1)*limit}} of {{totalRecords}}
                        </template>  
                </Paginator>
            </div>
        </div>
        <!-- delete component Modal -->
        <Dialog dismissableMask="true"  v-model:visible="deleteComponetModal" :style="{width: '350px'}" :modal="true">
            <template #header>
                <h3>Delete Component</h3>
            </template>
            <div class="confirmation-content">
                <span>Are your sure you want to delete this</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="deleteComponetModal = false" class="p-button-info p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="deleteComponent()" class="p-button-info p-button-text" autofocus />
            </template>
        </Dialog>
        <!-- Loader -->
        <div class="loader" id="propertiesLoader" v-if = "loader">
            <ProgressSpinner style="width:50px;height:50px" />
        </div>
</template>

<script>
import mehtods from './component-list'
import cm from 'maksof-common'
export default {
    name: "VIEW COMPONENT",
    data(){
        return{
            limit               : 10,
            page                : 0,
            totalRecords        : 0,
            filter              : {},
            componentid         : false,
            displayBasic        : false,
            components          : '',
            deleteComponetModal : false,
            loader              : false
        }
    },
    async mounted(){
        this.getAllComponent()
    },
    methods:{
        async onPage(event){ 
            this.page = parseInt(event.page) * parseInt(event.rows);
            this.projects = await this.getAllComponent();
        },
        async getAllComponent(){
            this.loader = true;
            let res = await  mehtods.getAllComponent(this.page, this.limit, this.filter);
            if (res.status == 'OK'){
                this.loader = false;
                res.data.forEach(d => {
                    d.date = cm.formatFullDayNameDMY(d.createdon)
                });
                this.totalRecords = res.totalrecord;
                this.components = res.data;
            }
        },
        async editComponent (componentid){
            this.loader = true;
            this.$router.push({ path: '/admin/edit-component/'+componentid});
        },
        async deleteComponent(){
            this.loader = true;
            this.deleteComponetModal = false;
            let result = await mehtods.deleteComponent(this.componentid)
            if (result.status == 'OK') {
                // this.page, this.limit, this.filter
                this.projects = await this.getAllComponent(this.page, this.limit, this.filter);
                this.loader = false;
            }
            else{
                this.loader = false
                alert(result.message);
            } 
        },
    }
};
</script>

<style lang="scss" scoped>
@import "components-list.scss";
.loader{
    position: fixed;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1500;
}
</style>